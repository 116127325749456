import { DatePicker } from "antd";
import dayjs from "dayjs";

interface ITaskDeadline {
    value?: any;
    onChange?: (value: any) => void;
}

const TaskDeadline = ({ value, onChange }: ITaskDeadline) => {
    return (
        <DatePicker
            placeholder="Без срока"
            disabledDate={(current: any) => {
                const today = dayjs().toDate();
                today.setHours(0);
                today.setMinutes(0);
                return current.weekday() > 4 || today > current;
            }}
            needConfirm={false}
            showTime={{
                showHour: true,
                showMinute: true,
            }}
            onChange={(value) => {
                onChange!(value != null ? value.format("YYYY-MM-DD HH:mm:ss") : null);
            }}
            value={value ? dayjs(value) : null}
            format={"DD-MM-YYYY HH:mm:ss"}
        />
    );
};

export default TaskDeadline;
