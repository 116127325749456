import {
    CheckCircleTwoTone,
    ClockCircleTwoTone,
    CloseOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined,
    SendOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import {
    Button,
    Checkbox,
    Divider,
    Input,
    Select,
    Space,
    Table,
    Typography,
    message,
    notification,
} from "antd";
import { useEffect, useRef, useState } from "react";
import TasksService from "../../../service/TasksService";
import Deadline from "../Deadline/Deadline";
import UserSelect from "../UserSelect";
import "./TableView.scss";

const RawHTML = ({ children, className = "" }) => (
    <div
        className={className}
        dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "<br />") }}
    />
);

const TableView = ({
    users,
    updateTask,
    setChecked,
    checked,
    groups,
    setGroups,
    targetTask,
    setTargetTask,
    deleteTask,
    tasks,
    user,
}) => {
    const [task, setTask] = useState({});
    const title = task.title;
    const description = task.description;
    const comment = task.comment;

    const [userGroup, setUserGroup] = useState(false);
    const [groupName, setGroupName] = useState("");

    const [sending, setSending] = useState([]);
    const [errors, setErrors] = useState({
        title: false,
        description: false,
        group: false,
        directors: false,
        executors: false,
        responsibles: false,
    });
    const inputRef = useRef(null);
    const findUsers = (ids) => {
        try {
            if (ids === "" || ids === null || typeof ids == "object") return [];
            const idsArr = ids?.split(",");
            if (idsArr === undefined) return [];
            return idsArr.map((id) => {
                const user = users.find((item) => item.id === Number(id));
                if (user) return user;
                return { name: "Аноним" };
            });
        } catch (e) {
            console.log("ERROR IDS", e);
            console.log("ERROR IDS", ids);
            return;
        }
    };

    useEffect(() => {
        if (targetTask)
            setTask({
                ...targetTask,
                executors: targetTask.executors,
                responsibles: targetTask.responsibles,
                directors: findUser(targetTask.directors),
            });
        else setTask({});
    }, [targetTask]);
    const findUser = (id) => {
        const user = users.find((item) => item.id === Number(id));
        console.log("user", user);
        if (user) return user;

        return { name: "Аноним" };
    };

    const updateTask_ = (task_) => {
        const error = checkErrors(task_);
        if (!error);
        console.log(task_);
        TasksService.updateTask({
            ...task_,
            responsibles:
                task_.responsibles == null || task_.responsibles == ""
                    ? task_.responsibles
                    : task_.responsibles.id,
            directors:
                task_.directors == null || task_.directors == ""
                    ? task_.directors
                    : task_.directors.id,
            executors: task_.executors.map((item) => item.id).join(","),
        })
            .then((response) => {
                updateTask(response.data);
                setTask({});
            })
            .catch((err) => {
                console.log("err update task", err);
                message.error("Не удалось обновить задачу");
            });
    };

    const sycnTask = (task) => {
        console.log(task);

        setSending([...sending, task.id]);
        TasksService.syncTasks([task.id])
            .then((response) => {
                if (response.data[task.id] != null) updateTask({ ...task, sync: 1 });
                else {
                    throw "error";
                }
            })
            .catch((err) => {
                message.error("Не удалось отправить в Bitrix задачу");
            })
            .finally(() => setSending(sending.filter((item) => item != task.id)));
    };
    useEffect(() => {
        // if (title !== task.title || description !== task.description || comment !== task.comment)
        //     updateTask_({ ...task, title: title, description: description, comment: comment })
    }, [title, description, comment]);
    const updateChecked = (task) => {
        if (!task?.create)
            if (checked.includes(task.id)) {
                setChecked(checked.filter((item) => item !== task.id));
            } else {
                setChecked([...checked, task.id]);
            }
    };
    const goToTask = (task) => {
        if (task.sync) {
            window.open(
                `https://vakio.bitrix24.ru/company/personal/user/${user.bitrix_id}/tasks/task/view/${task.bitrix_id}/`,
                "_blank",
                "noopener,noreferrer"
            );
        }
    };
    const checkErrors = () => {
        let error = false;
        let errors_ = {
            title: false,
            description: false,
            group: false,
            directors: false,
            executors: false,
            responsibles: false,
        };

        if (task.title == "") {
            errors_.title = true;
            error = true;
        }
        if (task.description == "") {
            errors_.description = true;
            error = true;
        }
        if (task.directors == null) {
            errors_.directors = true;
            error = true;
        }

        if (task.responsibles == null) {
            errors_.responsibles = true;
            error = true;
        }
        if (task.task_group_id == null) {
            errors_.group = true;
            error = true;
        }
        setErrors(errors_);
        return error;
    };
    const createGroup = (e, task) => {
        e.preventDefault();
        if (groupName != "")
            TasksService.createGroup({
                title: groupName,
                type: userGroup ? "user" : "public",
            })
                .then((response) => {
                    console.log("data", response);
                    setGroupName("");
                    setGroups([...groups, response.data]);
                    setTimeout(() => {
                        inputRef.current?.focus();
                    }, 0);
                })
                .catch((err) => {
                    console.log("create group err", err);
                    message.error("Не удалось создать группу.");
                });
    };

    const deleteTaskConfirm = (task) => {
        console.log(task);
        if (task.id === 0) {
            console.log("Delete task new");
            setTargetTask(null);
        } else
            TasksService.deleteTask(task.id)
                .then(() => {
                    deleteTask(task.id);
                    message.success("Задача успешно удалена.");
                })
                .catch((err) => {
                    message.error("Не удалось удалить задачу.");
                });
    };

    return (
        <div className="table-task">
            <Table
                pagination={false}
                rowClassName={(record, index) =>
                    record?.result ? "done-task" : undefined
                }
                rowKey={"id"}
                columns={[
                    {
                        key: "task_group_id",
                        dataIndex: "task_group_id",
                        title: "Событие",
                        render: (task_group_id, full) => {
                            return (
                                <div>
                                    {task.id === 0 && full.id === task.id ? (
                                        <Select
                                            value={task.task_group_id}
                                            onChange={(value) => {
                                                setErrors({ ...errors, group: false });
                                                setTask({
                                                    ...task,
                                                    task_group_id: value,
                                                });
                                            }}
                                            placeholder="Событие"
                                            style={{ width: 100 }}
                                            status={errors.group ? "error" : undefined}
                                            showSearch
                                            filterOption={(inputValue, option) => {
                                                return option.label
                                                    ?.toLowerCase()
                                                    .includes(inputValue?.toLowerCase());
                                            }}
                                            options={groups
                                                .filter((item) => item.id != "new")
                                                .map((group) => {
                                                    return {
                                                        label: group.title,
                                                        value: group.id,
                                                    };
                                                })}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider
                                                        style={{ margin: "8px 0" }}
                                                    />
                                                    <Space
                                                        style={{ padding: "0 8px 4px" }}
                                                    >
                                                        <Input
                                                            placeholder="Создать группу"
                                                            ref={inputRef}
                                                            value={groupName}
                                                            onChange={(e) =>
                                                                setGroupName(
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <Button
                                                            type="text"
                                                            icon={<PlusOutlined />}
                                                            onClick={createGroup}
                                                        ></Button>
                                                    </Space>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <Checkbox
                                                            style={{ marginLeft: 10 }}
                                                            checked={userGroup}
                                                            onChange={(e) =>
                                                                setUserGroup(
                                                                    e.target.checked
                                                                )
                                                            }
                                                        >
                                                            Личная
                                                        </Checkbox>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    ) : (
                                        groups.find(
                                            (group) => group.id === Number(task_group_id)
                                        )?.title
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        key: "title",
                        dataIndex: "title",
                        title: "Задача",
                        width: 300,
                        render: (value, full) => {
                            return (
                                <Typography.Paragraph
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        goToTask(full);
                                    }}
                                    className="table-task__info__description"
                                    editable={
                                        !user?.portal_isAdmin &&
                                        !user?.portal_isSupervisor &&
                                        !full?.create &&
                                        full.created_by !== user.id
                                            ? undefined
                                            : {
                                                  icon: <Icon type="xxx" />,
                                                  editing: task.id === full.id,
                                                  onChange: (text) =>
                                                      setTask({ ...task, title: text }),
                                              }
                                    }
                                >
                                    {task.id !== full.id ? value : task.title}
                                </Typography.Paragraph>
                            );
                        },
                    },
                    {
                        key: "description",
                        dataIndex: "description",
                        title: "Описание",
                        width: 300,
                        render: (value, full) => {
                            return (
                                <Typography.Paragraph
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    className="table-task__info__description"
                                    editable={
                                        !user?.portal_isAdmin &&
                                        !user?.portal_isSupervisor &&
                                        !task?.create &&
                                        full.created_by !== user.id
                                            ? undefined
                                            : {
                                                  icon: <Icon type="xxx" />,
                                                  editing: task.id === full.id,
                                                  onChange: (text) =>
                                                      setTask({
                                                          ...task,
                                                          description: text,
                                                      }),
                                              }
                                    }
                                >
                                    {task.id !== full.id ? value : task.description}
                                </Typography.Paragraph>
                            );
                        },
                    },
                    {
                        key: "result",
                        dataIndex: "result",
                        title: "Результат",
                        width: "10%",
                        render: (value, full) => {
                            return (
                                <>{value ? <RawHTML>{value}</RawHTML> : <div></div>}</>
                            );
                        },
                    },
                    {
                        key: "deadline",
                        dataIndex: "deadline",
                        title: "Срок",
                        render: (value, full) => {
                            return (
                                <Deadline
                                    offFocus={true}
                                    editing={task.id === full.id}
                                    disabled={
                                        !user?.portal_isAdmin &&
                                        !user?.portal_isSupervisor &&
                                        !full.create &&
                                        full.created_by !== user.id
                                    }
                                    deadline={task.id === full.id ? task.deadline : value}
                                    setDeadline={(value) =>
                                        setTask({ ...task, deadline: value })
                                    }
                                    updateTask={updateTask_}
                                    task={task.id === full.id ? task : full}
                                />
                            );
                        },
                    },
                    {
                        key: "sync",
                        dataIndex: "sync",
                        title: "Статус",
                        render: (value) => {
                            return Number(value) ? (
                                <div
                                    className="table-task__info__header__sync"
                                    style={{ fontSize: 12 }}
                                >
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                </div>
                            ) : (
                                <div className="table-task__info__header__sync no-sync">
                                    <ClockCircleTwoTone twoToneColor="#f1d03d" />
                                </div>
                            );
                        },
                    },
                    {
                        key: "directors",
                        dataIndex: "directors",
                        title: "Постановщик",
                        render: (value, full) => {
                            return (
                                <div>
                                    <UserSelect
                                        //disabled={(task.id !== full.id)}
                                        offFocus={true}
                                        editing={task.id === full.id}
                                        multiple={false}
                                        error={errors.directors}
                                        users={users}
                                        key_select={"directors"}
                                        task={task.id === full.id ? task : full}
                                        updateTask={updateTask_}
                                        selectedUsers={
                                            task.id === full.id
                                                ? task.directors
                                                : findUser(value)
                                        }
                                        setSelectedUsers={(value) => {
                                            setErrors({ ...errors, description: false });
                                            setTask({ ...task, directors: value });
                                        }}
                                        rawUsers={
                                            task.id === full.id
                                                ? task.directors
                                                : full.directors
                                        }
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        key: "responsibles",
                        dataIndex: "responsibles",
                        title: "Ответственный",
                        render: (value, full) => {
                            return (
                                <div>
                                    <UserSelect
                                        //disabled={(task.id !== full.id)}
                                        offFocus={true}
                                        editing={task.id === full.id}
                                        multiple={false}
                                        error={errors.responsibles}
                                        users={users}
                                        key_select={"responsibles"}
                                        task={task.id === full.id ? task : full}
                                        updateTask={updateTask_}
                                        selectedUsers={
                                            task.id === full.id
                                                ? task.responsibles
                                                : findUser(value)
                                        }
                                        setSelectedUsers={(value) => {
                                            setErrors({ ...errors, description: false });
                                            setTask({ ...task, responsibles: value });
                                        }}
                                        rawUsers={
                                            task.id === full.id
                                                ? task.responsibles
                                                : full.responsibles
                                        }
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        key: "executors",
                        dataIndex: "executors",
                        title: "Исполнители",
                        render: (value, full) => {
                            return (
                                <div>
                                    <UserSelect
                                        //disabled={(task.id !== Number(full.id))}
                                        multiple={true}
                                        offFocus={true}
                                        error={errors.executors}
                                        editing={task.id === full.id}
                                        users={users}
                                        key_select={"executors"}
                                        task={task.id === full.id ? task : full}
                                        updateTask={updateTask_}
                                        selectedUsers={
                                            task.id === full.id
                                                ? task.executors == ""
                                                    ? []
                                                    : task.executors || []
                                                : findUsers(value)
                                        }
                                        setSelectedUsers={(value) => {
                                            setErrors({ ...errors, description: false });
                                            setTask({ ...task, executors: value });
                                        }}
                                        rawUsers={
                                            task.id === full.id
                                                ? task.executors
                                                : full.executors
                                        }
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        key: "actions",
                        dataIndex: "actions",
                        title: "",
                        render: (value, full) => {
                            return (
                                <div>
                                    {task.id === full.id ? (
                                        <Button
                                            type="link"
                                            icon={
                                                <SaveOutlined
                                                    style={{ color: "#11a911" }}
                                                />
                                            }
                                            onClick={() => {
                                                updateTask_(task);
                                            }}
                                        />
                                    ) : (
                                        <Button
                                            disabled={
                                                full.sync ||
                                                (!user?.portal_isAdmin &&
                                                    !user?.portal_isSupervisor &&
                                                    full.created_by !== user.id)
                                            }
                                            type="link"
                                            icon={<EditOutlined />}
                                            onClick={() => {
                                                setTask({
                                                    ...full,
                                                    executors: findUsers(full.executors),
                                                    responsibles: findUser(
                                                        full.responsibles
                                                    ),
                                                    directors: findUser(full.directors),
                                                });
                                                console.log("set task", full);
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        key: "actions",
                        dataIndex: "actions",
                        title: "",
                        render: (value, full) => {
                            return (
                                <div>
                                    {task.id === full.id ? (
                                        <Button
                                            type="link"
                                            icon={
                                                <CloseOutlined style={{ color: "red" }} />
                                            }
                                            onClick={() => {
                                                setTask({});
                                            }}
                                        />
                                    ) : (
                                        <Button
                                            disabled={
                                                full.sync ||
                                                (!user?.portal_isAdmin &&
                                                    !user?.portal_isSupervisor &&
                                                    full.created_by !== user.id)
                                            }
                                            loading={sending.includes(full.id)}
                                            type="link"
                                            icon={<SendOutlined />}
                                            onClick={() => sycnTask(full)}
                                        />
                                    )}
                                </div>
                            );
                        },
                    },
                ]}
                dataSource={task.id === 0 ? [{ ...task }, ...tasks] : tasks}
                rowSelection={{
                    selectedRowKeys: checked,
                    onChange: setChecked,
                }}
            />
        </div>
    );
};

export default TableView;
