import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboards: [],
    modalData: [],
    dashboard: null,
    copingDashboard: null,
    targetPreset: {},
    rerenderDashboard: true,
    modalRequestData: {},
    rerenderId: false,
    rerenderId: false,
    modalPreset: {},
    editeMode: false,
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setDashboard: (state, action) => {
            const id = action.payload;
            state.dashboard = id;
            localStorage.setItem("dashboard", id);
        },
        setUpdatingDashboard: (state, action) => {
            const id = action.payload;
            const dashboard = dashboard.dashboards.find((item) => item.id === id);
            state.updatingDashboard = dashboard;
        },
        setCopingDashboard: (state, action) => {
            state.copingDashboard = action.payload;
        },
        setDashboards: (state, action) => {
            state.dashboards = action.payload;
        },

        createDashboard: (state, action) => {
            state.dashboards = [...state.dashboards, action.payload];
            state.dashboard = action.payload.id;
            localStorage.setItem("dashboard", action.payload.id);
        },
        updateDashboards: (state, action) => {
            const newdashboard = action.payload;
            const indexDashboard = state.dashboards.findIndex(
                (item) => item.id === newdashboard.id
            );
            state.dashboards[indexDashboard] = newdashboard;
            localStorage.setItem("dashboard", newdashboard.id);
            //    state.dashboard = [...state.dashboard, action.payload];
        },
        deleteDashboard: (state, action) => {
            const id = action.payload;

            state.dashboards = state.dashboards.filter((item) => item.id !== id);
            localStorage.removeItem("dashboard");
            //    state.dashboard = [...state.dashboard, action.payload];
        },
        setTargetPreset: (state, action) => {
            state.targetPreset = action.payload;
        },
        setModalPreset: (state, action) => {
            state.modalPreset = action.payload;
        },

        setRerenderPresetId: (state, action) => {
            state.rerenderId = action.payload;
        },
        setRerenderDashboard: (state, action) => {
            state.rerenderDashboard = action.payload;
        },
        setModalRequestdata: (state, action) => {
            state.modalRequestData = action.payload;
        },
        setEditeMode: (state, action) => {
            state.editeMode = action.payload;
        },
    },
});

export const {
    setEditeMode,
    setCopingDashboard,
    setModalRequestdata,
    setModalPreset,
    setRerenderDashboard,
    setRerenderPresetId,
    setDashboards,
    setDashboard,
    createDashboard,
    updateDashboards,
    setUpdatingDashboard,
    deleteDashboard,
    setTargetPreset,
} = dashboardSlice.actions;
export const reducer = dashboardSlice.reducer;
