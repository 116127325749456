import { $api, analytic_url } from "./http";

export default class TasksService {
    // Предоставляет статистику по продажам юридическим лицам по головным организациям за заданный период.
    static async getHeadOrgStats(dateStart, dateEnd, filters = []) {
        return $api.post(`/api/headOrgStats?period=${dateStart},${dateEnd}`, filters, {
            baseURL: analytic_url,
        });
    }
    static async getTasks(page, limit, filters, order, orderType) {
        return $api.post("/api/task_item_search", {
            page,
            limit,
            order_by: order,
            order_type: orderType,
            filters: filters,
        });
    }
    static async createTask(task) {
        return $api.post("/api/task_item", task);
    }

    static async syncTasks(tasks) {
        return $api.post("/api/task_item/sync", { data: tasks });
    }
    static async getResultTasks(tasks) {
        return $api.post("/api/task_item/sync_update", { data: tasks });
    }
    static async updateTask(task) {
        return $api.put("/api/task_item/" + task.id, task);
    }
    static async deleteTask(id) {
        return $api.delete("/api/task_item/" + id);
    }
    static async getGroups() {
        return $api.get("/api/task_group");
    }
    static async createGroup(group) {
        return $api.post("/api/task_group", group);
    }
    static async updateGroup(group) {
        return $api.put("/api/task_group/" + group.id, group);
    }
    static async deleteGroup(id) {
        return $api.delete("/api/task_group/" + id);
    }
    static async getPdfTasks(ids) {
        return $api.post("/api/generate_pdf", {
            type: "tasks_pdf",
            data: ids,
        });
    }
}
