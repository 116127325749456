import { TableOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Checkbox, Empty, Segmented, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TasksService from "../service/TasksService";
import { setHeaderTitle, setTasksIds } from "../store/layoutSlice";
import MindMapComponent from "./components/MindMap/MindMap";
import TableView from "./components/TableView/TableView";
import { TasksList } from "./components/TasksList";
import { getFilters, getGroupedTasks } from "./lib";
import { CreateGroupButton } from "./NewComponents/CreateGroup";
import { CreateTaskButton } from "./NewComponents/CreateTask";
import InfinityScroll from "./NewComponents/InfinityScroll/InfinityScroll";
import { usePreloadEntities } from "./NewComponents/PreloadEntities";
import { TaskOrder } from "./NewComponents/TaskOrder";
import { TasksFilters } from "./NewComponents/TasksFilters";
import "./Tasks.scss";

const Tasks = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [sendLoad, setSendLoad] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [taskGrouped, setTaskGrouped] = useState([]);
    const [checked, setChecked] = useState([]);
    const [targetTask, setTargetTask] = useState(null);
    const [openMind, setOpenMind] = useState(false);
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [loadingGetTasks, setLoadingGetTasks] = useState(false);
    const [order, setOrder] = useState("created_at");
    const [orderType, setOrderType] = useState("DESC");
    const [isDone, setIsDone] = useState(false);
    const [viewMode, setViewMode] = useState("List");
    const [movedTask, setMovedTask] = useState(0);
    const [targetMindGroup, setTargetMindGroup] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const loadRef = useRef(null);
    const [filters, setFilters] = useState({
        group: null,
        user: null,
        sync: "in_work",
    });

    const { loadingUsers, loadingGroups, groups, users, setGroups } =
        usePreloadEntities();

    const setGroupFilter = (value) => {
        setFilters((prev) => ({
            ...prev,
            group: value,
        }));
    };
    const setSyncFilter = (value) => {
        setFilters((prev) => ({
            ...prev,
            sync: value,
        }));
    };

    const dispatch = useDispatch();

    const groupingTasks = (tasks) => {
        setTaskGrouped(getGroupedTasks(tasks));
    };

    const getTasks = (page) => {
        const filtersBody = getFilters(filters);
        if (loadRef.current) {
            clearTimeout(loadRef.current);
        }
        if (page === 1) setTasks([]);
        setLoading(true);
        loadRef.current = setTimeout(() => {
            TasksService.getTasks(page, limit, filtersBody, order, orderType)
                .then((response) => {
                    if (response.data.result.length < limit) {
                        console.log("THERE");
                        setIsDone(true);
                    }
                    const newTasks =
                        page === 1
                            ? response.data.result
                            : [...tasks, ...response.data.result];
                    setTasks(newTasks);
                    groupingTasks(newTasks);
                })
                .catch((err) => {
                    console.log("Get tasks error", err);
                    message.error("Не удалось загрузить задачи.");
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 500);
    };

    useEffect(() => {
        if (loadingGroups) return;
        if (!targetTask?.create) {
            setTargetTask(null);
        }

        setPage(1);
        setIsDone(false);
        getTasks(1);
    }, [filters.sync, filters.group, loadingGroups, filters.user, order, orderType]);

    useEffect(() => {
        setViewMode(localStorage.getItem("tasks_view") || "List");
        dispatch(setHeaderTitle("Задачи"));
        console.log("set view mode", localStorage.getItem("tasks_view"));
        const group_id = new URLSearchParams(location.search).get("group_id");
        if (group_id) {
            setGroupFilter(Number(group_id));
        } else {
            setGroupFilter(0);
        }
    }, []);

    const addTask = (task) => {
        console.log("ADD TASK", task);
        setTasks((prevTasks) => {
            groupingTasks([task, ...prevTasks]);
            return [task, ...prevTasks];
        });
        dispatch(
            setTasksIds((prevTasks) => {
                return [task, ...prevTasks].map((item) => item.id);
            })
        );
    };
    const updateTask = (task) => {
        console.log("UPDATED TASK", task, tasks);
        const newTasks = tasks.map((task_) => {
            if (task_.id === task.id) {
                return task;
            }
            return task_;
        });
        setTasks(newTasks);
        dispatch(setTasksIds(newTasks.map((item) => item.id)));
        groupingTasks(newTasks);
    };
    const deleteTask = (id) => {
        const newTasks = tasks.filter((task) => task.id != id);
        setTasks(newTasks);
        dispatch(setTasksIds(newTasks.map((item) => item.id)));

        groupingTasks(newTasks);
    };
    const checkedAll = () => {
        if (checked.length === tasks.length) {
            setChecked([]);
        } else {
            setChecked(tasks.map((task) => task.id));
        }
    };

    const syncTasks = () => {
        sendToBitrix(checked);
    };
    const sendToBitrix = (checked) => {
        setSendLoad(true);
        TasksService.syncTasks(checked)
            .then((response) => {
                console.log(response.data);
                const newTasks = tasks.map((task_) => {
                    if (checked.includes(task_.id)) {
                        return { ...task_, sync: 1 };
                    }
                    return task_;
                });
                setChecked([]);
                setTasks(newTasks);
                dispatch(setTasksIds(newTasks.map((item) => item.id)));

                groupingTasks(newTasks);
                message.success("Задачи успешно отправлены в Bitrix");
            })
            .catch((err) => {
                message.error("Не удалось отправить задачи в Bitrix");
            })
            .finally(() => {
                setSendLoad(false);
            });
    };
    const getFromBitrix = () => {
        setLoadingGetTasks(true);
        TasksService.getResultTasks(checked)
            .then((response) => {
                console.log("response", response);
                const newTasks = tasks.map((task_) => {
                    if (checked.includes(task_.id)) {
                        if (response.data[task_.id]) {
                            return { ...response.data[task_.id] };
                        } else {
                            return task_;
                        }
                    }
                    return task_;
                });
                setChecked([]);
                setTasks(newTasks);
                dispatch(setTasksIds(newTasks.map((item) => item.id)));

                groupingTasks(newTasks);
                message.success("Задачи успешно обновленны из Bitrix");
            })
            .catch((err) => {
                message.error("Не удалось обновить задачи из Bitrix");
            })
            .finally(() => {
                setLoadingGetTasks(false);
            });
    };
    const updateGroupFilter = (value) => {
        setGroupFilter(value);
        setPage(1);
        setIsDone(false);
        if (value != null)
            navigate({
                pathname: "/portal-tasks",
                search: "?group_id=" + value,
            });
        else {
            navigate({
                pathname: "/portal-tasks",
            });
        }
    };

    const updateViewMode = (value) => {
        localStorage.setItem("tasks_view", value);
        setPage(1);
        setIsDone(false);
        setViewMode(value);
    };

    const addGroup = (group) => {
        setSyncFilter(0);
        setGroups([...groups, group]);
        setGroupFilter(group.id);
    };

    const onScrollEnd = () => {
        if (!isDone) {
            setPage(page + 1);
            getTasks(page + 1);
            setLoading(true);
        }
    };

    return (
        <>
            <InfinityScroll
                isEmpty={!loading && tasks.length === 0}
                empty={<Empty description={"Задачи не найдены"} />}
                loading={loading}
                isDone={isDone}
                onScrollEnd={onScrollEnd}
            >
                <div
                    className="tasks"
                    style={{
                        width: viewMode === "Table" ? "100%" : undefined,
                    }}
                >
                    <div className="tasks__add-new">
                        <CreateTaskButton
                            onAdd={addTask}
                            initialUsers={users}
                            targetGroup={filters.group}
                            userId={user.id}
                        />
                        <CreateGroupButton addGroup={addGroup} />
                    </div>
                    <div className="tasks__filter">
                        <TasksFilters
                            users={users}
                            groups={groups}
                            filters={filters}
                            onChange={setFilters}
                        />
                        {(user?.portal_isAdmin || user?.portal_isSupervisor) && (
                            <div className="tasks__filter__actions">
                                <Checkbox
                                    indeterminate={
                                        checked.length &&
                                        !(checked.length === tasks.length)
                                    }
                                    style={{ fontSize: 14 }}
                                    checked={
                                        checked.length === tasks.length &&
                                        tasks.length > 0
                                    }
                                    onChange={checkedAll}
                                >
                                    Выделить все
                                </Checkbox>
                                <Button
                                    loading={sendLoad}
                                    style={{ marginRight: 10 }}
                                    type={"primary"}
                                    onClick={syncTasks}
                                >
                                    Отправить
                                </Button>
                                <Button
                                    type={"default"}
                                    onClick={getFromBitrix}
                                    loading={loadingGetTasks}
                                >
                                    Получить из Bitrix
                                </Button>
                            </div>
                        )}
                    </div>
                    <div style={{ display: "flex" }}>
                        <Segmented
                            style={{ marginTop: 10 }}
                            value={viewMode}
                            onChange={updateViewMode}
                            options={[
                                {
                                    value: "List",
                                    icon: <UnorderedListOutlined />,
                                },
                                {
                                    value: "Table",
                                    icon: <TableOutlined />,
                                },
                            ]}
                        />
                        <TaskOrder
                            order={order}
                            orderType={orderType}
                            onChangeOrder={setOrder}
                            onChangeOrderType={setOrderType}
                        />
                    </div>
                    <>
                        {viewMode === "Table" ? (
                            <>
                                <TableView
                                    targetTask={targetTask}
                                    user={user}
                                    task={targetTask}
                                    deleteTask={deleteTask}
                                    groups={groups}
                                    setGroups={setGroups}
                                    setChecked={setChecked}
                                    checked={checked}
                                    setTargetTask={setTargetTask}
                                    updateTask={updateTask}
                                    users={users}
                                    tasks={tasks}
                                    loading={false || loadingUsers}
                                    setTasks={setTasks}
                                />
                            </>
                        ) : (
                            <TasksList
                                targetTask={targetTask}
                                user={user}
                                setOpenMind={setOpenMind}
                                loadingUsers={loadingUsers}
                                loading={loading}
                                taskGrouped={taskGrouped}
                                setTargetMindGroup={setTargetMindGroup}
                                loadingGroups={loadingGroups}
                                sendToBitrix={sendToBitrix}
                                tasks={tasks}
                                users={users}
                                updateTask={updateTask}
                                setTasks={setTasks}
                                setChecked={setChecked}
                                groupingTasks={groupingTasks}
                                setGroupFilter={setGroupFilter}
                                checked={checked}
                                groups={groups}
                                setGroups={setGroups}
                                setTargetTask={setTargetTask}
                                deleteTask={deleteTask}
                                setMovedTask={setMovedTask}
                                movedTask={movedTask}
                            />
                        )}
                    </>
                </div>
                <MindMapComponent
                    visible={openMind}
                    setVisible={(state) => {
                        setOpenMind(state);
                        setTargetMindGroup(null);
                    }}
                    group={targetMindGroup}
                    users={users}
                    tasks={targetMindGroup ? taskGrouped?.[targetMindGroup.id] : []}
                />
            </InfinityScroll>
        </>
    );
};

export default Tasks;
