import { Form, message } from "antd";
import { useEffect, useState } from "react";
import TasksService from "../../../../service/TasksService";

interface IUseCreateTask {
    initialTask: Task;
    initialUsers?: any[];
    onCancel: () => void;
    onAdd: (task: Task) => void;
}

export const useCreateTask = ({
    initialTask,
    initialUsers,
    onCancel,
    onAdd,
}: IUseCreateTask) => {
    const [users, setUsers] = useState<any[]>(initialUsers ?? []);
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue(initialTask);
    }, []);

    const onSubmit = () => {
        form.validateFields().then((values: any) => {
            console.log("VALUES", values);

            values.responsibles.forEach((responsible: any) => {
                TasksService.createTask({
                    title: values.title,
                    description: values.description,
                    task_group_id: values.task_group_id,
                    executors:
                        values.executors.length === 0 ? null : values.executors.join(","),
                    directors: values.directors,
                    responsibles: responsible,
                    deadline: values.deadline,
                    comment: values.comment,
                })
                    .then((response) => {
                        onAdd(response.data);
                        //addTask({ ...task, ...response.data, create: undefined });
                        onCancel();
                        message.success("Задача успешно создана.");
                    })
                    .catch((err) => {
                        console.log("Create task error", err);
                        message.error("Не удалось создать задачу.");
                    })
                    .finally(() => {});
            });
        });
    };

    return { users, form, onSubmit };
};
