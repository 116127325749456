import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { getInitialTask } from "../lib";
import CreateTaskModal from "./CreateTaskModal";

interface ICreateTaskButton {
    targetGroup: string | null;
    initialUsers?: any[];
    onAdd: (task: Task) => void;

    userId: number;
}

const CreateTaskButton = ({
    targetGroup,
    initialUsers,
    userId,
    onAdd,
}: ICreateTaskButton) => {
    const [visible, setVisible] = useState(false);

    const onClick = () => {
        setVisible(true);
    };
    return (
        <>
            <div className="tasks__add-new__task" onClick={onClick}>
                <PlusOutlined style={{ fontSize: 30 }} />
                Добавить задачу
            </div>
            <CreateTaskModal
                initialUsers={initialUsers}
                visible={visible}
                setVisible={setVisible}
                onAdd={onAdd}
                initialTask={getInitialTask(targetGroup, userId)}
            />
        </>
    );
};

export default CreateTaskButton;
