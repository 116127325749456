import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useDeleteDashboard } from "../model";

interface IDeleteDashboardButton {}

const DeleteDashboardButton = ({}: IDeleteDashboardButton) => {
    const { onSubmit, loading } = useDeleteDashboard();

    return (
        <>
            <Popconfirm
                title="Удалить выбранную панель?"
                okText="Удалить"
                okButtonProps={{
                    danger: true,
                }}
                onConfirm={onSubmit}
            >
                <Button
                    danger
                    icon={<DeleteOutlined />}
                    style={{ marginLeft: 10 }}
                    type="link"
                    loading={loading}
                    size="large"
                />
            </Popconfirm>
        </>
    );
};

export default DeleteDashboardButton;
