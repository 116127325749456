import { dashboardApi, dashboardModel } from "@entities/dashboard";
import { message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useDeleteDashboard = () => {
    const [loading, setLoading] = useState(false);
    const userId = useSelector((state: RootState) => state.user.id);
    const navigate = useNavigate();
    const dashboard = useSelector(dashboardModel.selectCurrentDashboard);

    //portal_user,d_manager,super_admin,service_manager,portal_admin,electrical
    const dispatch = useDispatch();
    const onSubmit = (values: any) => {
        setLoading(true);
        dashboardApi
            .deleteDashboard(dashboard.id)
            .then((response: any) => {
                dispatch(dashboardModel.deleteDashboard(dashboard.id));
                navigate({
                    search: "",
                });
                message.success(`Панель ${dashboard.title} успешно удалена!`, 1);
            })
            .catch((e: any) => {
                message.error(`Не удалось создать панель`, 0.5);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        onSubmit,
        loading,
        dashboard,
    };
};
