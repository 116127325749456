import { Button, Checkbox, Form, Input, Select, Space } from "antd";
import { useCreateTask } from "../model";
import SelectTaskGroup from "./SelectTaskGroup";
import TaskDeadline from "./TaskDeadline";
import TaskUsers from "./TaskUsers";
import styled from "styled-components";

interface ICreateTaskForm {
    initialTask: Task;
    initialUsers?: any[];
    onCancel: () => void;
    onAdd: (task: Task) => void;
}

const FormItem = styled(Form.Item)`
    & .ant-form-item-label {
        padding: 0px 0px 1px !important;
        & > label {
        }
    }
`;
// id: 0,
//         create: true,
//         task_group_id: targetGroup ?? "new",
//         title: "",
//         comment: "",
//         files: [],
//         description: "",
//         executors: "",
//         directors: userId,
//         responsibles: undefined,
//         deadline: moment()
//             .set({ hour: 18, minute: 0 })
//             .add(2, "day")
//             .format("YYYY-MM-DD HH:mm:ss"),

const CreateTaskForm = ({
    initialTask,
    initialUsers,
    onCancel,
    onAdd,
}: ICreateTaskForm) => {
    const { form, users, onSubmit } = useCreateTask({
        initialTask,
        initialUsers,
        onCancel,
        onAdd,
    });

    return (
        <div>
            <Form
                name="task"
                // labelCol={{ flex: "110px" }}
                colon={false}
                layout="vertical"
                form={form}
            >
                <FormItem
                    style={{ marginBottom: 4 }}
                    label="Имя"
                    required
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </FormItem>
                <FormItem style={{ marginBottom: 4 }} label="Описание" name="description">
                    <Input.TextArea />
                </FormItem>
                <FormItem
                    style={{ flexDirection: "row", marginBottom: 4 }}
                    label="Группа"
                    name="task_group_id"
                >
                    <SelectTaskGroup />
                </FormItem>
                <FormItem style={{ marginBottom: 4 }} label="Комментарий" name="comment">
                    <Input.TextArea />
                </FormItem>
                <FormItem style={{ marginBottom: 4 }} label="Срок" name="deadline">
                    <TaskDeadline />
                </FormItem>
                <FormItem
                    style={{ marginBottom: 4 }}
                    label="Ответственные (Для каждого будет создана задача)"
                    name="responsibles"
                    required
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <TaskUsers users={users} multiple />
                </FormItem>
                <FormItem label="Соисполнители" name="executors">
                    <TaskUsers users={users} multiple />
                </FormItem>
            </Form>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Space>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" onClick={onSubmit}>
                        Создать
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default CreateTaskForm;
