import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button, Segmented } from "antd";

interface ITaskOrder {
    order: TaskOrder;
    orderType: OrderType;
    onChangeOrder: (order: TaskOrder) => void;
    onChangeOrderType: (orderType: OrderType) => void;
}

const TaskOrder = ({
    order,
    orderType,
    onChangeOrder,
    onChangeOrderType,
}: ITaskOrder) => {
    const _onChangeOrderType = () => {
        onChangeOrderType(orderType === "ASC" ? "DESC" : "ASC");
    };

    return (
        <div
            style={{
                margin: "10px 20px 0px 20px",
                display: "flex",
                alignItems: "center",
            }}
        >
            <span style={{ marginRight: 10 }}>Сортировка:</span>
            <Segmented
                value={order}
                onChange={onChangeOrder}
                options={[
                    {
                        label: "Дата создания",
                        value: "created_at",
                    },
                    {
                        label: "Дата завершения",
                        value: "deadline",
                    },
                ]}
            />
            <div style={{ marginLeft: 5 }}>
                <Button
                    onClick={_onChangeOrderType}
                    type="text"
                    icon={
                        orderType == "DESC" ? (
                            <ArrowDownOutlined size={20} />
                        ) : (
                            <ArrowUpOutlined size={20} />
                        )
                    }
                />
            </div>
        </div>
    );
};

export default TaskOrder;
