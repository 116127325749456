import { Checkbox, Form, Input, message, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import TasksService from "../../../../service/TasksService";

interface ICreateGroupModal {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    addGroup: (group: TaskGroup) => void;
}

const CreateGroupModal = ({ visible, setVisible, addGroup }: ICreateGroupModal) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    function sendForm() {
        form.validateFields()
            .then((values: any) => {
                console.log(values);
                setLoading(true);
                TasksService.createGroup({
                    title: values.title,
                    description: values.description,
                    type: values.user ? "user" : "public",
                })
                    .then((response: any) => {
                        console.log("data", response);
                        addGroup(response.data);
                        setVisible(false);
                    })
                    .catch((err) => {
                        console.log("create group err", err);
                        message.error("Не удалось создать группу.");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((error) => {
                console.log("Validate Failed:", error);
            });
    }
    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);

    return (
        <Modal
            title={<Typography.Title level={4}>{"Создат событие"}</Typography.Title>}
            centered
            open={visible}
            onOk={sendForm}
            okText={"Создать"}
            onCancel={() => setVisible(false)}
            closable={false}
            destroyOnClose
            forceRender
            width={window.screen.width < 450 ? "95%" : 500}
            confirmLoading={loading}
            // zIndex={10000}
            styles={{ body: { paddingBottom: 16 } }}
        >
            <Form
                name="task"
                // labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Имя события"
                    required
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Описание"
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    valuePropName="checked"
                    name="user"
                    style={{ display: "flex" }}
                    initialValue={false}
                >
                    <Checkbox>Личное событие</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateGroupModal;
