import { ApartmentOutlined } from "@ant-design/icons";
import { Button, Divider, Empty, Spin } from "antd";
import Task from "../../Task/Task";

const TasksList = ({
    targetTask,
    loadingUsers,
    loading,
    taskGrouped,
    setTargetMindGroup,
    loadingGroups,
    sendToBitrix,
    tasks,
    users,
    updateTask,
    setTasks,
    setChecked,
    groupingTasks,
    setGroupFilter,
    checked,
    groups,
    setGroups,
    setTargetTask,
    setOpenMind,
    deleteTask,
    user,
    setMovedTask,
    movedTask,
}) => {
    const groupsByIds = groups.reduce((prev, current) => {
        return {
            ...prev,
            [current.id]: current,
        };
    }, {});

    return (
        <>
            {loadingUsers ? (
                <div className="tasks__load">{/* <Spin /> */}</div>
            ) : (
                <>
                    {tasks.length > 0 ? (
                        <>
                            {tasks.map((task) => {
                                return (
                                    <div key={task.id}>
                                        <Task
                                            openMindMapButton={(group) => (
                                                <Button
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    shape="circle"
                                                    icon={<ApartmentOutlined />}
                                                    onClick={() => {
                                                        setTargetMindGroup(group);
                                                        setOpenMind(true);
                                                    }}
                                                />
                                            )}
                                            taskGroup={groupsByIds[task.task_group_id]}
                                            setMovedTask={setMovedTask}
                                            movedTask={movedTask}
                                            user={user}
                                            files={task.files}
                                            sendToBitrix={sendToBitrix}
                                            deleteTask={deleteTask}
                                            groups={groups}
                                            setGroups={setGroups}
                                            setChecked={setChecked}
                                            checked={checked}
                                            setTargetTask={setTargetTask}
                                            updateTask={updateTask}
                                            users={users}
                                            setGroupFilter={setGroupFilter}
                                            groupingTasks={groupingTasks}
                                            tasks={tasks}
                                            task={task}
                                            setTasks={setTasks}
                                            key={task.id}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <div></div>
                    )}
                </>
            )}
        </>
    );
};

export default TasksList;
