import { UserAccess } from "@entities/user";
import { RequestedSelect } from "@shared/ui/RequestedSelect";
import { Checkbox, Form, Input, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import { analytic_url } from "../../../../service/http";

interface IEditDashboardModal {
    omSubmit: (values: any) => void;
    onCancel: () => void;
    dashboard: TDashboard;
    visible: boolean;
}

const EditDashboardModal = ({
    omSubmit,
    onCancel,
    visible,
    dashboard,
}: IEditDashboardModal) => {
    const [usersIds, setUsersIds] = useState<number[]>(dashboard?.usersIds ?? []);
    const [form] = Form.useForm();
    useEffect(() => {
        if (form) {
            setUsersIds(dashboard?.usersIds ?? []);
            form.setFieldsValue(dashboard);
        }
    }, [visible]);
    const onOk = () => {
        form.validateFields()
            .then((values) => {
                omSubmit(values);
            })
            .catch(() => {});
    };

    const onSelectUsers = (usersIds: any) => {
        form.setFieldValue("usersIds", usersIds);
        setUsersIds(usersIds);
    };
    return (
        <Modal
            title={
                <Typography.Title level={4}>{`${
                    dashboard?.id
                        ? dashboard?.copy
                            ? "Копировать"
                            : "Обновить"
                        : "Создать"
                } панель`}</Typography.Title>
            }
            centered
            open={visible}
            onOk={onOk}
            okText={`${
                dashboard?.id ? (dashboard?.copy ? "Копировать" : "Обновить") : "Создать"
            }`}
            onCancel={onCancel}
            closable={false}
            destroyOnClose
            width={window.screen.width < 450 ? "100%" : 500}
            // zIndex={10000}
            styles={{ body: { paddingBottom: 16 } }}
        >
            <Form
                name="title"
                // labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Имя панели"
                    required
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <UserAccess.AdminAccess>
                    <Form.Item
                        label="Дополнительно доступно для"
                        initialValue={[]}
                        required
                        name="usersIds"
                    >
                        <RequestedSelect
                            mode="multiple"
                            onlyRead={false}
                            onSelect={onSelectUsers}
                            values={usersIds}
                            requestUrl={`${analytic_url}/api/users-list`}
                            queryTypedParam="name"
                            queryChoosenParams="usersIds"
                            valueLabel="name"
                            valueKey="id"
                        />
                    </Form.Item>
                </UserAccess.AdminAccess>

                <UserAccess.AdminAccess>
                    <Form.Item
                        initialValue={false}
                        valuePropName="checked"
                        required
                        name="is_public"
                        rules={[]}
                    >
                        <Checkbox>Открытая для всех</Checkbox>
                    </Form.Item>
                </UserAccess.AdminAccess>
            </Form>
        </Modal>
    );
};

export default EditDashboardModal;
