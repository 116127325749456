import { Modal } from "antd";
import CreateTaskForm from "./CreateTaskForm";

interface ICreateTaskModal {
    initialTask: Task;
    initialUsers?: any[];
    visible: boolean;
    setVisible: (visible: boolean) => void;
    onAdd: (task: Task) => void;
}

const CreateTaskModal = ({
    initialTask,
    initialUsers,
    visible,
    setVisible,
    onAdd,
}: ICreateTaskModal) => {
    const onCancel = () => {
        setVisible(false);
    };
    return (
        <Modal
            title="Создать задачу"
            destroyOnClose
            open={visible}
            onCancel={onCancel}
            footer={[]}
            maskClosable={false}
        >
            <CreateTaskForm
                initialUsers={initialUsers}
                initialTask={initialTask}
                onCancel={onCancel}
                onAdd={onAdd}
            />
        </Modal>
    );
};

export default CreateTaskModal;
