import { Spin } from "antd";
import { ReactNode, useState } from "react";

interface IInfinityScroll {
    children: ReactNode;
    loading?: boolean;
    isEmpty?: boolean;
    empty?: ReactNode;
    isDone?: boolean;
    onScrollEnd: () => Promise<boolean>;
}

const InfinityScroll = ({
    children,
    isEmpty,
    empty,
    loading,
    isDone,
    onScrollEnd,
}: IInfinityScroll) => {
    const getData = () => {
        onScrollEnd();
    };
    return (
        <div
            style={{
                height: "100%",
                overflow: "auto",
            }}
            onScroll={(event) => {
                //@ts-ignore
                const { scrollHeight, scrollTop, clientHeight } =
                    event.nativeEvent.target;
                const isBottomReached =
                    scrollHeight - Math.round(scrollTop) < clientHeight + 400;
                if (isBottomReached && !isDone && !loading) {
                    getData();
                }
            }}
        >
            {children}
            {loading && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 30,
                    }}
                >
                    <Spin />
                </div>
            )}
            {isEmpty && empty}
        </div>
    );
};

export default InfinityScroll;
