import moment from "moment";

export const getInitialTask = (targetGroup: string | null, userId: number) => {
    return {
        id: 0,
        create: true,
        task_group_id: targetGroup,
        title: "",
        comment: "",
        files: [],
        description: "",
        executors: [],
        directors: userId,
        responsibles: [],
        deadline: moment()
            .set({ hour: 18, minute: 0 })
            .add(2, "day")
            .format("YYYY-MM-DD HH:mm:ss"),
    };
};
