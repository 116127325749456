import { Select } from "antd";

interface ITaskUsers {
    users: any[];
    multiple?: boolean;
    value?: any;
    onChange?: (value: any) => void;
}

const TaskUsers = ({ multiple, value, users, onChange }: ITaskUsers) => {
    const onChange_ = (value: any) => {
        onChange?.(value);
    };
    return (
        <Select
            allowClear
            mode={multiple ? "multiple" : undefined}
            showSearch
            onChange={onChange_}
            value={value ?? null}
            options={users.map((user) => {
                return { label: user.name, value: user.id };
            })}
            filterOption={(inputValue, option) => {
                return option!.label?.toLowerCase().includes(inputValue?.toLowerCase());
            }}
        />
    );
};

export default TaskUsers;
