import { useEffect, useState } from "react";
import TasksService from "../../../service/TasksService";
import UserService from "../../../service/UserService";
import { message } from "antd";

export const usePreloadEntities = () => {
    const [groups, setGroups] = useState<TaskGroup[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingGroups, setLoadingGroups] = useState(true);

    useEffect(() => {
        console.log("set view mode", localStorage.getItem("tasks_view"));
        setLoadingUsers(true);
        UserService.getUsers()
            .then((response) => {
                setUsers(response.data.filter((item: any) => item.id != null));
            })
            .catch((err) => {
                message.error("Не удалось загрузить пользователей");
                console.log("Error get users", err);
            })
            .finally(() => {
                setLoadingUsers(false);
            });

        setLoadingGroups(true);
        TasksService.getGroups()
            .then((response) => {
                setGroups([
                    { id: "new", title: "Новая задача", description: "" },
                    ...response.data,
                ]);
            })
            .catch((err) => {
                console.log("Error get users", err);
            })
            .finally(() => {
                setLoadingGroups(false);
            });
    }, []);

    return {
        loadingUsers,
        loadingGroups,
        groups,
        users,
        setGroups,
    };
};
