import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import CreateGroupModal from "./CreateGroupModal";

interface ICreateGroupButton {
    addGroup: (group: TaskGroup) => void;
}

const CreateGroupButton = ({ addGroup }: ICreateGroupButton) => {
    const [visible, setVisible] = useState(false);

    const onClick = () => {
        setVisible(true);
    };

    return (
        <>
            <div className="tasks__add-new__group" onClick={onClick}>
                <PlusOutlined style={{ fontSize: 30 }} />
                Создать событие
            </div>
            <CreateGroupModal
                visible={visible}
                setVisible={setVisible}
                addGroup={addGroup}
            />
        </>
    );
};

export default CreateGroupButton;
