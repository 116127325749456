import { dashboardApi, dashboardModel } from "@entities/dashboard";
import { message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useCopyDashboard = () => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const userId = useSelector((state: RootState) => state.user.id);
    const navigate = useNavigate();
    const dashboard = useSelector(dashboardModel.selectCurrentDashboard);
    const copiedDashboard = useSelector(dashboardModel.selectCopingDashboard);

    //portal_user,d_manager,super_admin,service_manager,portal_admin,electrical
    const dispatch = useDispatch();
    const onSubmit = (values: any) => {
        setLoading(true);
        dashboardApi
            .createDashboard({
                ...copiedDashboard,
                ...values,
                id: undefined,
            })
            .then((response: any) => {
                dispatch(
                    dashboardModel.createDashboard({
                        ...response.data,
                        content: response.data.content,
                    })
                );
                navigate({
                    search: "?dashboard_id=" + dashboard.id,
                });
                message.success(`Панель ${response.data.title} успешно создана!`, 1);
                setVisible(false);
            })
            .catch((e: any) => {
                message.error(`Не удалось создать панель`, 0.5);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const onOpenModal = () => {
        dispatch(
            dashboardModel.setCopingDashboard({
                ...dashboard,
                creator: userId,
                is_public: false,
                copy: true,
                usersIds: [],
            })
        );
        setVisible(true);
    };
    const onCancel = () => {
        setVisible(false);
    };
    return {
        onSubmit,
        onOpenModal,
        visible,
        onCancel,
        copiedDashboard,
        loading,
    };
};
