export const getGroupedTasks = (tasks: Task[]) => {
    let taskGroups: any = [];
    let groupingTasks: any = {};
    tasks.forEach((task) => {
        const groupTask = task.task_group_id; //groups.find(group => group.id === task.task_group_id);
        if (groupTask !== null) {
            if (taskGroups.includes(groupTask)) {
                groupingTasks[groupTask].push({ ...task });
            } else {
                taskGroups.push(groupTask);
                groupingTasks[groupTask] = [task];
            }
        }
    });
};

// { value: "none", label: "Все" },
// { value: "sended", label: "Отправленные" },
// { value: "not_sended", label: "Не отправленные" },
// { value: "done", label: "Завершенные" },

export const getFilters = (filters: TaskFilters) => {
    let filtersBody = [];
    if (filters.group) {
        filtersBody.push({
            field: "task_group_id",
            value: filters.group,
            comparison: "=",
        });
    }
    if (filters.sync != null && filters.sync !== "none") {
        switch (filters.sync) {
            case "sended":
                filtersBody.push({
                    field: "sync",
                    value: 1,
                    comparison: "=",
                });
                break;
            case "done":
                filtersBody.push({
                    field: "done",
                    value: 1,
                    comparison: "=",
                });

                break;
            case "in_work":
                filtersBody.push({
                    field: "done",
                    value: 0,
                    comparison: "=",
                });

                break;
            case "not_sended":
                filtersBody.push({
                    field: "sync",
                    value: 0,
                    comparison: "=",
                });
                break;
            case "done_no_result":
                filtersBody.push({
                    field: "done",
                    value: 2,
                    comparison: "=",
                });
                break;
        }
    }
    if (filters.user) {
        filtersBody.push({
            field: "responsibles,executors",
            value: [filters.user],
            comparison: "=",
        });
    }
    return filtersBody;
};
