import { Radio, Select } from "antd";
import { taskTypeOptions } from "../lib";

interface ITasksFilters {
    filters: TaskFilters;
    groups: TaskGroup[];
    users: any[];
    onChange: (filters: TaskFilters) => void;
}

const TasksFilters = ({ filters, groups, users, onChange }: ITasksFilters) => {
    const onChangeGroup = (value: typeof filters.group) => {
        onChange({
            ...filters,
            group: value,
        });
    };
    const onChangeUser = (value: typeof filters.user) => {
        onChange({
            ...filters,
            user: value,
        });
    };
    const onChangeSync = (value: typeof filters.sync) => {
        onChange({
            ...filters,
            sync: value,
        });
    };
    return (
        <div className="tasks__filter__inputs">
            <div>
                <Select
                    allowClear
                    showSearch
                    style={{ width: 200, marginRight: 20 }}
                    placeholder="Событие"
                    value={filters.group}
                    filterOption={(inputValue, option) => {
                        return option!.label
                            ?.toLowerCase()
                            .includes(inputValue?.toLowerCase());
                    }}
                    onChange={onChangeGroup}
                    options={[{ id: 0, title: "Все" }, ...groups]
                        .filter((task) => task.id !== "new")
                        .map((group) => {
                            return {
                                value: group.id,
                                label: group.title,
                            };
                        })}
                />

                <Select
                    placeholder="Пользователь"
                    allowClear
                    showSearch
                    key={"id"}
                    onChange={onChangeUser}
                    style={{ width: 200 }}
                    value={filters.user}
                    options={users.map((user) => {
                        return { label: user.name, value: user.id };
                    })}
                    filterOption={(inputValue, option) => {
                        return option!.label
                            ?.toLowerCase()
                            .includes(inputValue?.toLowerCase());
                    }}
                />
            </div>
            <Radio.Group
                value={filters.sync}
                buttonStyle="solid"
                style={{ marginRight: 10, marginTop: 10 }}
                onChange={(event) => {
                    onChangeSync(event.target.value);
                }}
            >
                {taskTypeOptions.map((key) => {
                    return (
                        <Radio.Button key={key.value} value={key.value}>
                            {key.label}
                        </Radio.Button>
                    );
                })}
            </Radio.Group>
            {/* <Checkbox checked={myFilter} onChange={(e) => setMyFilter(e.target.checked)}>Только личные</Checkbox> */}
        </div>
    );
};

export default TasksFilters;
