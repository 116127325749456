import { Layout } from "antd";
import "normalize.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";
import LoginPage from "../../Auth/LoginPage";
import DealersPage from "../../Dealers/DealersPage";
import DashboardPage from "../../NewDashboard/DashboardPage";
import QuestionsPage from "../../Questions/QuestionsPage";
import Chapter from "../../Questions/components/Chapter/Chapter";
import Item from "../../Questions/components/Item/Item";
import Rubric from "../../Questions/components/Rubric/Rubric";
import SignUp from "../../SignUp/SignUp";
import Tasks from "../../Tasks/Tasks";
import UsersPage from "../../Users/UsersPage";
import UserService from "../../service/UserService";
import { setUser, signIn, signOut } from "../../store/userSlice";
import Header from "./components/Header/index";
import Sidebar from "./components/Sidebar/index";
import VideoGuidesPage from "@pages/VideoGuidesPage";
import { ManagersPage } from "@pages/ManagersPage";

const cookies = new Cookies();

const AppRouter = () => {
    const user = useSelector((state) => state.user);
    const [collapsed, setCollapsed] = useState(window.screen.width > 450 ? false : true);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const storageUser = localStorage.getItem("user");
        const access_token =
            cookies.get("accessToken") ?? localStorage.getItem("accessToken");
        const refresh_token = cookies.get("refreshToken");
        const user_ =
            cookies.get("user") ?? (storageUser ? JSON.parse(storageUser) : undefined);
        if (access_token && user_) {
            dispatch(signIn({ access_token, refresh_token }));
            dispatch(setUser(user_));
            UserService.getUserInfo()
                .then((response) => {
                    dispatch(setUser(response.data.user));
                })
                .catch((error) => {
                    dispatch(signOut());
                });
        }
        setLoading(false);
        // eslint-disable-next-line
    }, []);
    const visibleRule =
        user.portal_isUser ||
        user.portal_isAdmin ||
        user.portal_isSupervisor ||
        user.d_isAdminManager ||
        user.d_isManager ||
        user.f_isEditor ||
        user.isAdmin ||
        user.s_isAdmin;
    return (
        <>
            {!loading && (
                <>
                    {user.authorized ? (
                        <Layout style={{ height: "100vh" }}>
                            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
                            <Layout className="site-layout">
                                <Header state={collapsed} setState={setCollapsed} />
                                <Layout.Content
                                    style={{
                                        padding: "16px",
                                        paddingLeft:
                                            window.screen.width > 450 ? "16px" : 0,
                                        paddingRight:
                                            window.screen.width > 450 ? "16px" : 0,
                                        overflowY: "auto",
                                    }}
                                >
                                    <Routes>
                                        {visibleRule && (
                                            <>
                                                {
                                                    <Route
                                                        path={"/portal-dashboard"}
                                                        element={<DashboardPage />}
                                                    />
                                                }
                                                <Route
                                                    path={"/portal-tasks"}
                                                    element={<Tasks />}
                                                />
                                                <Route
                                                    path={"/portal-services"}
                                                    element={<DealersPage />}
                                                />
                                                <Route
                                                    path={"/portal-questions"}
                                                    element={<QuestionsPage />}
                                                />
                                                <Route
                                                    path={"/portal-questions/chapter/:id"}
                                                    element={<Chapter />}
                                                />
                                                <Route
                                                    path={"/portal-questions/rubric/:id"}
                                                    element={<Rubric />}
                                                />
                                                <Route
                                                    path={"/portal-questions/item/:id"}
                                                    element={<Item />}
                                                />
                                                <Route
                                                    path={"/managers"}
                                                    element={<ManagersPage />}
                                                />
                                                <Route
                                                    path={"/portal-info"}
                                                    element={<VideoGuidesPage />}
                                                />
                                            </>
                                        )}
                                        {(user.isAdmin || user.isSystemAdmin) && (
                                            <Route
                                                path={"/portal-users"}
                                                element={<UsersPage />}
                                            />
                                        )}
                                    </Routes>
                                </Layout.Content>
                            </Layout>
                        </Layout>
                    ) : (
                        <Routes>
                            <Route path={"/login"} element={LoginPage} />
                            <Route path={"/sign-in"} element={LoginPage} />
                            <Route path={"/sign-up"} element={<SignUp />} />
                            <Route path="*" element={<LoginPage />} />
                        </Routes>
                    )}
                </>
            )}
        </>
    );
};

export default AppRouter;
