import { Select } from "antd";
import { useEffect, useState } from "react";
import TasksService from "../../../../service/TasksService";

interface ISelectTaskGroup {
    value?: any;
    onChange?: (value: any) => void;
}

const SelectTaskGroup = ({ value, onChange }: ISelectTaskGroup) => {
    const [groups, setGroups] = useState<TaskGroup[]>([]);
    const [loading, setLoading] = useState(false);
    const loadGroups = () => {
        setLoading(true);
        TasksService.getGroups()
            .then((response) => {
                setGroups(response.data);
            })
            .catch((err) => {
                console.log("Error get users", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        loadGroups();
    }, []);

    return (
        <Select
            size="middle"
            value={value}
            onChange={onChange}
            placeholder="Событие"
            style={{ width: "100%" }}
            showSearch
            filterOption={(inputValue, option) => {
                return option.label?.toLowerCase().includes(inputValue?.toLowerCase());
            }}
            options={
                groups
                    .filter((item) => item.id != "new")
                    .map((group) => {
                        return { label: group.title, value: group.id };
                    }) as TaskGroup[]
            }
        />
    );
};

export default SelectTaskGroup;
