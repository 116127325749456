import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import { Form, Input, Button, Image, Typography } from "antd";
import { UserOutlined, CheckOutlined } from "@ant-design/icons";
import UserService from "../service/UserService";
import { useSelector } from "react-redux";
import "./SignUp.scss";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
    const formRef = React.createRef();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [isDone, setIsDone] = useState(false);
    const onFinish = (values) => {
        setLoading(true);
        setError(false);

        UserService.signUp(values.username)
            .then((response) => {
                if (
                    "error" in response.data &&
                    response.data?.error !==
                        "Пользователь существует, новый пароль отправлен на почту и в оповещения Битрикс24"
                ) {
                    setError(true);
                    setErrorMessage(response.data.error);
                } else {
                    setIsDone(true);
                }
            })
            .catch((error) => {
                setError(true);
                setErrorMessage("Не удалось пройти регистрацию");
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {}, [user.authorized]);
    // Ваш пароль отправлен в уведомлении bitrix и на почту
    return (
        <div className="signin">
            {isDone ? (
                <div className="info-form">
                    <div className="info-form__result">
                        <CheckOutlined style={{ color: "#52c41a", fontSize: 70 }} />
                        {/* <Typography.Title level={4} style={{ textAlign: "center" }}>
                            {" "}
                            {`Регистрация прошла\nуспешно`}{" "}
                        </Typography.Title> */}
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <Typography.Text style={{ fontSize: 15, color: "#5f5f5f" }}>
                            Ваш пароль отправлен в уведомлении Bitrix и на почту
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 30,
                        }}
                    >
                        <Button
                            type="primary"
                            style={{ width: "100%" }}
                            onClick={() => {
                                navigate("login");
                            }}
                        >
                            Войти
                        </Button>
                    </div>
                </div>
            ) : (
                <Form
                    name="normal_username"
                    className="username-form signin__form"
                    onFinish={onFinish}
                    initialValues={{ remember: true }}
                    ref={formRef}
                >
                    <div className="signin__image-container">
                        <div className="signin__image-frame">
                            <Image preview={false} src={logo} alt="logo" />
                        </div>
                        <div className="signin__image-description">Портал</div>
                    </div>
                    <Typography.Text style={{ textAlign: "center" }}>
                        Для получения пароля введите E-mail, который указан в аккаунте
                        Bitrix.
                    </Typography.Text>

                    <Form.Item
                        name="username"
                        validateStatus={error ? "error" : undefined}
                        rules={[
                            { required: true, message: "Пожалуйста, заполните поле!" },
                        ]}
                        style={{ marginTop: 10 }}
                        help={error ? errorMessage : undefined}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="E-mail"
                            // value={isLoginAvailable ? username : ""}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="username-form-button"
                            loading={loading}
                        >
                            Получить пароль
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default SignUp;
